
import Order from '@/models/Order'
import NetworkService, { Endpoint } from './NetworkService'

export interface ICalculateInstallmentFeeParams {
  value: number
  installments: number
}

export default class OrderService extends NetworkService {

  listExamOrders = async (params: any) => {
    let res = await this.get(Endpoint.simulationOrders, params)
    res.data = res.data.map((elem: any) => new Order(elem))
    return res
  }

  listGroupOrders = async (params: any) => {
    let res = await this.get(Endpoint.myGroups, params)
    res.data = res.data.map((elem: any) => new Order(elem))
    return res
  }

  listInstallments = async (params: ICalculateInstallmentFeeParams) => {
    const res: any = await this.get(Endpoint.calculateInstallmentFee, params)

    res.formatted_value = Number(res.value).toCurrency();
    res.formatted_amount_to_receive = res.amount_to_receive.toCurrency();
    res.formatted_intallment_value = (
      Number(res.value) / Number(res.installments)
    ).toCurrency();
    res.formatted_intallment_value_to_receive =
      res.installments_value.toCurrency();
    res.formatted_fee = res.fee.toCurrency();
    return res
  }
  show = async (id: string) => {
    let res = await this.get(`${Endpoint.simulationOrders}/${id}`)
    res.data = new Order(res.data)
    return res
  }
  removeOrder(order_id: number, type: 'simulation' | 'group') {
    return type == 'group' ? this.post(Endpoint.revokeGroup, { user_bundle_id: order_id }) : this.post(Endpoint.revokeExam, { user_simulation_id: order_id })
  }
}
