import { prepareYoutubeLink } from "@/components/YoutubeInput/YoutubeInputController";
import { EMockExamType } from "@/enums";
import { ISelectOption } from "@/interfaces";
import { getFormattedFileName } from "@/utils/Helper";
import moment from "moment";
import CreatedAtModel from "./CreatedAtModel";

export default class Exam extends CreatedAtModel {
    id: number;
    title: string;
    price: number;
    description: string;
    category: string;
    exam_datetime?: string;
    exam_type: string;
    image: string;
    duration: number;
    introduction_text: string;
    introduction_video?: string;
    active: boolean;
    validity?: number;
    max_installments: number;
    resolution_video: string;
    question_count: number;
    alternatives_per_question: number;
    identifier: string;
    bench_id?: number;
    institute_id?: number;
    level_id?: number;
    office_id?: number;
    vacancies?: number;
    passing_score?: number;
    subscribers?: number;
    modality?: string;
    subscribers_vacancies_relation?: string;
    resolution_pdf: any[];
    exam_pdf: any[];
    office?: any;
    bench?: any;
    institute?: any;
    level?: any;
    comments?: any[];
    simulation_questions?: any[]

    constructor(data: any) {
        super(data)
        this.id = data.id
        this.title = data.title
        this.price = data.price
        this.description = data.description
        this.category = data.category
        this.exam_datetime = data.exam_datetime
        this.exam_type = data.exam_type
        this.image = data.image
        this.duration = data.duration
        this.introduction_text = data.introduction_text
        this.introduction_video = data.introduction_video
        this.active = data.active
        this.validity = data.validity
        this.max_installments = data.max_installments
        this.resolution_video = data.resolution_video
        this.question_count = data.question_count
        this.alternatives_per_question = data.alternatives_per_question

        this.identifier = data.identifier
        this.bench_id = data.bench_id
        this.institute_id = data.institute_id
        this.level_id = data.level_id
        this.office_id = data.office_id
        this.vacancies = data.vacancies
        this.passing_score = data.passing_score
        this.subscribers = data.subscribers
        this.modality = data.modality
        this.subscribers_vacancies_relation = data.subscribers_vacancies_relation
        this.resolution_pdf = data.resolution_pdf
        this.exam_pdf = data.exam_pdf
        this.office = data.office
        this.bench = data.bench
        this.institute = data.institute
        this.level = data.level
        this.simulation_questions = data.simulation_questions


        this.exam_pdf = data.exam_pdf?.map((elem: any) => {
            return {
                ...elem,
                name: getFormattedFileName(elem.url)
            }
        })
        this.resolution_pdf = data.resolution_pdf?.map((elem: any) => {
            return {
                ...elem,
                name: getFormattedFileName(elem.url)
            }
        })
        if (data.comments) {
            this.comments = data.comments.map((com: any) => {
                return { ...com, formatted_created_at: moment(com.created_at).format('DD/MM/YYYY - HH[h]mm') }
            })
        }
    }




    get formatted_image(): string | NodeRequire {
        return this.image || require('@/assets/img/image_placeholder.png')
    }
    get formatted_exam_datetime(): string {
        return this.exam_datetime ? moment(this.exam_datetime).format('DD/MM/YYYY - HH[h]mm') : ''
    }
    get formatted_duration(): string {
        return new Date(this.duration * 1000).toISOString().substr(11, 5).replace(':', 'h')
    }
    get formatted_status(): string {
        return this.active ? 'Ativo' : 'Inativo'
    }
    get formatted_type(): string {
        return this.exam_type == EMockExamType.cesgranrio ? 'Petrobras (Cesgranrio)' : EMockExamType.truefalse ? 'Certo ou errado' : 'Múltipla escolha'
    }
    get formatted_introduction_video(): string {
        return prepareYoutubeLink(this.introduction_video)
    }
    get formatted_resolution_video(): string {
        return prepareYoutubeLink(this.resolution_video)
    }
    get formatted_price(): string {
        return !this.price ? 'Gratuíto' : this.price.toCurrency().toString()
    }




    static MINIMUM_EXAM_PER_GROUP = 1
    static MINIMUM_CESGRANRIO_QUESTIONS = 20
    static modalityOptions: ISelectOption[] = [
        {
            id: 'Adaptado',
            title: 'Adaptado',
        },
        {
            id: 'Inédito',
            title: 'Inédito',
        },
        {
            id: 'Provas anteriores',
            title: 'Provas anteriores',
        },
    ]
    static examTypes = [
        {
            id: EMockExamType.multipe,
            title: 'Múltipla escolha'
        },
        {
            id: EMockExamType.truefalse,
            title: 'Certo ou errado'
        },
        {
            id: EMockExamType.cesgranrio,
            title: 'Petrobras (Cesgranrio)'
        },
    ] as ISelectOption[]

    static makeAlternatives = (alternativesCount: number, examType: EMockExamType) => {
        let answers = []
        const startIndex = 65
        // eu somo 65 + qtd de alternativas e vejo até qual letra vai ter
        const lastIndex = startIndex + alternativesCount
        // no loop eu converto o codigo pra letra e add no array
        // não, eu nao usei o Gepeto 😎
        for (let index = startIndex; index < lastIndex; index++) {
            if (index < 90) {
                answers.push(String.fromCharCode(index))
            }
        }
        return examType == EMockExamType.multipe || examType == EMockExamType.cesgranrio ? answers : ['C', 'E']
    }

}