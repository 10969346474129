import moment from "moment";
import CreatedAtModel from "./CreatedAtModel";

export default class User extends CreatedAtModel {
    id: number;
    name: string;
    email: string;
    nickname: string;
    document: string;
    phone_area: string;
    phone_number: string;
    profile_picture?: string;
    isAdmin: boolean;

    constructor(data: any) {
        super(data)
        this.id = data.id
        this.name = data.name
        this.email = data.email
        this.nickname = data.nickname
        this.document = data.document
        this.phone_area = data.phone_area
        this.phone_number = data.phone_number
        this.isAdmin = data.isAdmin
    }
}