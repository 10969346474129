import moment from "moment"

export default class CreatedAtModel {
    created_at: string
    constructor(data: any) {
        this.created_at = data.created_at
    }

    get formatted_created_at() {
        return moment(this.created_at).format('DD/MM/YYYY')
    }
}