
import { IGroupVideoResponse } from '@/components/GroupVideosAddComponent/GroupVideosAddComponentController'
import { IGroupPost, IUpcomingExamPost } from '@/interfaces'
import Group from '@/models/Group'
import NetworkService, { Endpoint } from './NetworkService'

export default class GroupService extends NetworkService {

  list = async (params: any) => {
    let res = await this.get(Endpoint.groups, params)
    res.data = res.data.map((elem: any) => new Group(elem))
    return res
  }
  show = async (id: string) => {
    let res = await this.get(`${Endpoint.groups}/${id}`)
    res.data = new Group(res.data)
    return res
  }
  create = (params: IGroupPost) => {
    return this.postMultipart(`${Endpoint.groups}`, params)
  }
  update = (id: string | number, params: any) => {
    return this.postMultipart(`${Endpoint.groupsUpdate}/${id}`, params)
  }
  createBonusPdf = (group_id: string, files: File) => {
    return this.postMultipart(`${Endpoint.groupsUpdate}/${group_id}`, { bonus_pdf: [files] })
  }
  deleteGroupBonusPdf = (id: string) => {
    return this.delete(`${Endpoint.removeGroupBonusPdf}?bonus_pdf_id=${id}`)
  }
  addUpcomingExam = (params: IUpcomingExamPost) => {
    return this.post(Endpoint.upcomingExams, params)
  }
  listUpcomingExams = (params: IUpcomingExamPost) => {
    return this.get(Endpoint.upcomingExams, params)
  }
  deleteUpcomingExam = (group_id: string, upcoming_exam_id: string) => {
    return this.delete(`${Endpoint.removeUpcomingExams}/${group_id}?simulation_coming_soon_id=${upcoming_exam_id}`)
  }
  deleteGroupExam = (group_id: string, exam_id: string) => {
    return this.delete(`${Endpoint.removeGroupExam}/${group_id}?simulation_id=${exam_id}`)
  }
  deleteGroupVideo = (id: string) => {
    return this.delete(`${Endpoint.deleteGroupVideo}/${id}`)
  }
  updateGroupVideo = (params: IGroupVideoResponse) => {
    return this.put(`${Endpoint.groupVideo}/${params.id}`, params)
  }
}