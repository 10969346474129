
import { IMockExamPost, ISelectOption } from '@/interfaces'
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'
import { EMockExamType } from '@/enums'
import { prepareYoutubeLink } from '@/components/YoutubeInput/YoutubeInputController'
import ICommentPost from '@/interfaces/ICommentPost'
import IMockExamUpdate from '@/interfaces/IMockExamUpdate'
import Exam from '@/models/Exam'

export default class ExamService extends NetworkService {
  list = async (params: any) => {
    let res = await this.get(Endpoint.mockExams, params)
    res.data = res.data.map((elem: any) => new Exam(elem))
    return res
  }
  show = async (id: string) => {
    let res = await this.get(`${Endpoint.mockExams}/${id}`)
    const commentRes = await this.get(`${Endpoint.testimony}?simulation_id=${id}`)
    res.data.comments = commentRes.data
    return res
  }
  create = (params: IMockExamPost) => {
    return this.postMultipart(`${Endpoint.mockExams}`, params)
  }
  createExamFiles = (simulation_id: string, files: File[]) => {
    return this.postMultipart(`${Endpoint.addExamPdf}`, { simulation_id, exam_pdf: files })
  }
  deleteExamFile = (id: string | number) => {
    return this.delete(`${Endpoint.deleteExamPdf}?id=${id}`)
  }
  createResolutionFiles = (simulation_id: string, files: File[]) => {
    return this.postMultipart(`${Endpoint.addResolutionPdf}`, { simulation_id, resolution_pdf: files })
  }
  deleteResolutionFile = (id: string | number) => {
    return this.delete(`${Endpoint.deleteResolutionPdf}?id=${id}`)
  }
  createComment = (params: ICommentPost) => {
    return this.postMultipart(`${Endpoint.testimony}`, params)
  }
  deleteComment = (id: string | number) => {
    return this.delete(`${Endpoint.testimony}/${id}`)
  }
  update = (id: number | string, params: Partial<IMockExamUpdate>) => {
    return this.postMultipart(`${Endpoint.updateExam}/${id}`, params)
  }
  updateExam = (id: number | string, params: Partial<IMockExamUpdate>) => {
    return this.put(`${Endpoint.updateExam}/${id}`, params)
  }
  annulQuestion = (question_number: number | string, simulation_id: number | string) => {
    return this.post(Endpoint.annulQuestion, { simulation_id, question_number })
  }
  getFormattedComment(comment: any) {
    // arrumar isso (fazer a interface do comment) e ver onde usa pra trocar
    try {
      comment.formatted_created_at = moment(comment.created_at).format('DD/MM/YYYY - HH[h]mm')
    } catch (e) {
      console.log(e)
    } finally {
      return comment
    }
  }
}
