
import { IAdmin, IAdminUpdate, ILogin } from '@/interfaces'
import User from '@/models/User'
import { MUTATIONS, store } from '@/store'
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'

export default class UserService extends NetworkService {
  listAdmins = (params: any) => {
    return this.get(Endpoint.users, { ...params, isAdmin: true })
  }
  createAdmin = (params: IAdmin) => {
    return this.post(Endpoint.usersAdmin, params)
  }
  list = async (params: any) => {
    let res = await this.get(Endpoint.users, params)
    res.data = res.data.map((data: any) => new User(data))
    return res
  }
  show = async (id: string) => {
    let res = await this.get(`${Endpoint.users}/${id}`)
    res.data = new User(res.data)
    return res
  }
  remove = (id: string) => {
    return this.delete(`${Endpoint.users}/${id}`)
  }
  update = (id: string, params: IAdminUpdate) => {
    return this.put(`${Endpoint.users}/${id}`, params)
  }
  login = async (params: ILogin) => {
    const res = await this.post(Endpoint.login, params)
    if (res.data.isAdmin) {
      return res
    } else {
      throw ({ status: false, message: 'Credenciais inválidas' })
    }
  }
  logout = () => {
    store.commit(MUTATIONS.CLEAR_SESSION)
  }
  getFormattedUser(data: any) {
    data.formatted_created_at = moment(data.created_at).format('DD/MM/YYYY')
    return data
  }
}