import moment from "moment";
import User from "./User";
import { ISelectOption } from "@/interfaces";
import Group from "./Group";
import Exam from "./Exam";
import CreatedAtModel from "./CreatedAtModel";

export default class Order extends CreatedAtModel {

    id: number;
    user_id: number;
    simulation_id: number;
    status: string;
    payment_status: string;
    payment_method?: string;
    payment_message?: string;
    simulation?: Exam
    bundle?: Group
    user?: User;

    constructor(data: any) {
        super(data)
        this.id = data.id
        this.user_id = data.user_id
        this.simulation_id = data.simulation_id
        this.status = data.status
        this.payment_status = data.payment_status
        this.payment_method = data.payment_method
        this.payment_message = data.payment_message
        if (data.simulation) {
            this.simulation = new Exam(data.simulation)
        }
        if (data.bundle) {
            this.bundle = new Group(data.bundle)
        }
        if (data.user) {
            this.user = new User(data.user)
        }
    }

    get formatted_title(): string {
        if (this.simulation) {
            return this.simulation?.title
        }
        if (this.bundle) {
            return this.bundle?.title
        }
        return ''
    }

    get formatted_payment_method(): string | undefined {
        if (this.payment_method) {
            return this.payment_method == 'credit_card' ? 'Cartão de crédito' : 'Pix'
        }
    }
    get formatted_status(): string {
        return this.status.capitalizeFirst().toString()
    }

    static installmentOptions: ISelectOption[] = Array(12).fill('').map((elem: any, index: number) => {
        return index == 0 ? {
            id: '1',
            title: 'Somente à vista'
        } : {
            id: (index + 1).toString(),
            title: `Até ${index + 1} vezes`
        }
    })
    static expirationOptions: ISelectOption[] = [
        {
            id: null, title: 'Indefinido',
        },
        {
            id: 365, title: '1 ano',
        },
        {
            id: 730, title: '2 anos',
        },
    ]

    static expirationValues: { [key: string]: { title: string } } =
        {
            "365": { title: '1 ano' },
            "730": { title: '2 anos' }
        }


}