import { ISelectOption } from "@/interfaces";
import { IStatus } from "@/interfaces/IStatus";
import { getFormattedFileName } from "@/utils/Helper";
import moment from "moment";
import CreatedAtModel from "./CreatedAtModel";

export enum EGroupStatus {
    published = 'publicado',
    unpublished = 'não listado'
}

export default class Group extends CreatedAtModel {

    id: number;
    title: string;
    image?: string;
    description?: string;
    benefits: string;
    status: string;
    validity?: string;
    max_installments: number;
    price: number;
    link_telegram: string;
    simulations_count: number;
    simulations?: any[];
    simulations_coming_soons?: any[];
    simulations_coming_soons_count: number;
    users_bundles_acquired_count: number;
    youtube_video?: any[];
    bonus_pdf?: any[];


    constructor(data: any) {
        super(data)
        this.id = data.id
        this.title = data.title
        this.benefits = data.benefits
        this.status = data.status
        this.max_installments = data.max_installments
        this.price = data.price
        this.link_telegram = data.link_telegram
        this.simulations = data.simulations
        this.simulations_count = data.simulations_count
        this.simulations_coming_soons = data.simulations_coming_soons
        this.simulations_coming_soons_count = data.simulations_coming_soons_count
        this.users_bundles_acquired_count = data.users_bundles_acquired_count
        this.youtube_video = data.youtube_video
        this.description = data.description
        this.validity = data.validity


        this.bonus_pdf = data.bonus_pdf?.map((elem: any) => {
            console.log(elem.url)
            return {
                ...elem,
                name: getFormattedFileName(elem.url)
            }
        })


    }


    get formatted_image(): string | NodeRequire {
        return this.image || require('@/assets/img/group_placeholder.png')
    }
    get formatted_price(): string {
        return !this.price ? 'Gratuíto' : this.price.toCurrency().toString()
    }

    get formatted_status(): IStatus | undefined {
        switch (this.status) {
            case EGroupStatus.published:
                return {
                    title: 'Publicado',
                    text_color: 'text-success'
                }
            case EGroupStatus.unpublished:
                return {
                    title: 'Não listado',
                    text_color: 'text-warning'
                }
        }
    }
    static statusOptions: ISelectOption[] = [
        {
            id: EGroupStatus.published,
            title: 'Publicado'
        },
        {
            id: EGroupStatus.unpublished,
            title: 'Não listado'
        },
    ]

}