
import NetworkService, { Endpoint } from './NetworkService'

export enum ESettingsKey {
  terms = 'terms_of_use',
  title_landing = 'title_landing',
  description_landing = 'description_landing'
}

export default class SettingsService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  show = (key?: ESettingsKey) => {
    const url = key ? `${Endpoint.settings}/${key}` : Endpoint.settings
    return this.network.get(url);
  };
  update = (key: ESettingsKey, value: string) => {
    return this.network.put(`${Endpoint.settings}/${key}`, { value });
  };
}